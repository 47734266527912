import * as React from 'react'
import Layout from '../components/layout'
import BlogList from '../components/blogList'
import SEO from '../components/seo'

export default function blogPage() {
  return (
    <Layout>
      <SEO
        title="Emiliano Viscarra's Blog"
        desc="Emiliano Viscarra's blog. Writing about software engineering, engineering culture and more."
      />
      {/* Header */}
      <div className="bg-white">
        <div className="py-16 lg:py-20">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              Blog
            </h1>
          </div>
          {/* Blog */}
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <BlogList />
          </div>
        </div>
      </div>
    </Layout>
  )
}

import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { format } from 'date-fns'

export default function BlogList() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            date
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            category
            language
          }
          excerpt
          html
          timeToRead
        }
      }
    }
  `)
  const { allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { nodes } = allMarkdownRemark
  const posts = mapPosts(nodes)

  function mapPosts(posts: Array<any>) {
    return posts.map(post => {
      const featuredImage = getImage(post.frontmatter.featuredImage)
      return {
        title: post.frontmatter.title,
        href: post.frontmatter.slug,
        category: post.frontmatter.category,
        description: post.excerpt,
        datetime: post.frontmatter.date,
        featuredImage: featuredImage,
        language: post.frontmatter.language,
        timeToRead: post.timeToRead,
      }
    })
  }

  return (
    <>
      {/* Decorative dot pattern */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-warm-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={384}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>
      </div>
      <section className="relative bg-white" aria-labelledby="blogList">
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-none">
          {posts.map(post => (
            <div
              key={post.title}
              className="sm:flex sm:items-center sm:justify-between rounded-lg shadow-lg overflow-hidden"
            >
              <div className="sm:order-last flex-shrink-0 relative">
                <GatsbyImage
                  image={post.featuredImage}
                  alt=""
                  className="h-60 w-full object-cover inset-0 bg-cover bg-center z-0"
                />
                <div className="absolute inset-0 z-10 flex justify-end text-4xl text-white font-semibold mr-2">
                  {post.language}
                </div>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-teal-600">
                    {post.category}
                  </p>
                  <Link to={post.href} className="block mt-2">
                    <p className="font-serif text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span className="sr-only">Emiliano Viscarra</span>
                    <StaticImage
                      src="../images/avatar-emi.jpg"
                      placeholder="blurred"
                      alt="Author Emiliano"
                      width={40}
                      height={40}
                      className="h-10 w-10 rounded-full"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      Emiliano Viscarra
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>
                        {format(new Date(post.datetime), 'PP')}
                      </time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.timeToRead} mins read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
